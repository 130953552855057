import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { storage } from "../../../firebase"
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getUtenteEsterno, modificaUtenteEsterno } from '../../../actions/utenteEsterno';
import DocumentoUtenteEsterno from "./DocumentoUtenteEsterno"
import { v4 as uuid } from 'uuid';




const ModificaUtenteEsterno = ({ utenteEsterno: { utenteEsterno, loading }, getUtenteEsterno, modificaUtenteEsterno, idUtenteEsterno, setOpenModificaUtenteEsterno, categorieDocumentiUtenteEsterno }) => {


    useEffect(() => {
        return () => {
            setDatiDipendente(dipendenteInitialState)
        }
    }, []);

    useEffect(() => {
        getUtenteEsterno(idUtenteEsterno);
    }, [getUtenteEsterno, idUtenteEsterno]);

    const dipendenteInitialState = {
        _id: "",
        nome: "",
        cognome: "",
        username: "",
        passCl: "",
        documenti: []
    }

    const [datiDipendente, setDatiDipendente] = useState(dipendenteInitialState);

    const [documentiUtenteEsterno, setDocumentiUtenteEsterno] = useState([]);
    const tipologieDocumenti = [{tipologia: "documento-amministrativo", nome: "Documento Amministrativo"}]
    const [cartellaSelected, setCartellaSelected] = useState(null);


    useEffect(() => {
        setDatiDipendente({
            _id: loading || !utenteEsterno ? '' : utenteEsterno._id,
            nome: loading || !utenteEsterno ? '' : utenteEsterno.nome,
            cognome: loading || !utenteEsterno ? '' : utenteEsterno.cognome,
            username: loading || !utenteEsterno ? '' : utenteEsterno.username,
            passCl: loading || !utenteEsterno ? '' : utenteEsterno.passCl,
            documenti: loading || !utenteEsterno || !utenteEsterno.documenti ? [] : utenteEsterno.documenti
        });
    }, [utenteEsterno, loading]);

    const {
        _id,
        nome,
        cognome,
        username,
        passCl,
        documenti,
    } = datiDipendente;

    useEffect(() => {
        let arrayDocumenti = []
        documenti.map(el => {
            if (el.tipologia == "documento-esterno") {
                arrayDocumenti.push({ id: uuid(), nome: el.nome, tipologia: el.tipologia, url: el.url, cartella: el.cartella })
            }
        })
        setDocumentiUtenteEsterno(arrayDocumenti)
    }, [documenti]);

    const [tabSelected, setTabSelected] = useState("anagrafica");

    const onChangeSetDatiDipendente = e => setDatiDipendente({ ...datiDipendente, [e.target.name]: e.target.value });



    const onSubmitmodificaUtenteEsterno = async e => {
        e.preventDefault();
        let arrayDocumenti = [...documentiUtenteEsterno]
        modificaUtenteEsterno({idUtenteEsterno, documenti: arrayDocumenti, nome, cognome, username, passCl})
    };



    const onChangeUploadFile = (e, id, tipologia) => {
        if (e.target.files[0]) {
            const file = e.target.files[0]
            if (!file) return;
            const storageRef = ref(storage, `utenti-esterni/${username}-${_id}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    console.log(progress);
                },
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        let array = [];
                        if (tipologia == "documento-esterno") {
                            array = [...documentiUtenteEsterno]
                        }
                        let index = array.findIndex(el => { return el.id == id })
                        if (index > -1) {
                            array[index].url = downloadURL
                        } else {
                            array.push({ nome: e.target.name, url: downloadURL, tipologia: tipologia, cartella: "" })
                        }
                        if (tipologia == "documento-esterno") {
                            setDocumentiUtenteEsterno(array)
                        }
                    });
                }
            );
        }
    }



    const onChangeNomeDocumento = (e, id) => {
        let array = [...documentiUtenteEsterno];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].nome = e.target.value
            setDocumentiUtenteEsterno(array);
        }
    }

    const onChangeCartellaDocumento = (e, id) => {
        let array = [...documentiUtenteEsterno];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].cartella = e
            setDocumentiUtenteEsterno(array);
        }
    }
    



    const onChangeTipologiaDocumento = (nome, id) => {
        let array = [...documentiUtenteEsterno];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].nome = nome
            setDocumentiUtenteEsterno(array)
        }
    }


    const onClickAggiungiRiga = (tabSelected) => {
        let array;
        if (tabSelected == "documenti") {
            array = [...documentiUtenteEsterno]
            array.push({ id: uuid(), url: "", nome: "", tipologia: "documento-esterno" })
            setDocumentiUtenteEsterno(array)
        }
    }

    const eliminaDocumento = (id) => {
        let array = [...documentiUtenteEsterno];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array.splice(index, 1)
            setDocumentiUtenteEsterno(array);
        }
    }

    return (loading ? <Spinner /> :
        <div className="dati_container modifica_dipendente">
            <div className="fun_assegnazione_incarichi">
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("anagrafica")} className={`button w-button ${tabSelected == "anagrafica" ? '' : 'button_disabled'}`}>Anagrafica</a>
                            </div>
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("documenti")} className={`button w-button ${tabSelected == "documenti" ? '' : 'button_disabled'}`}>Documenti</a>
                            </div>
                        </div>
                    </div>
                </div>
                {tabSelected == "documenti" ? 
                <div className="row">
                <div className="item_4 m_top_5">
                <label htmlFor="name">scegli cartella</label>
                        <div className="item_5_function">
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                <select className="form-control select" name="scadenza" onChange={e => setCartellaSelected(e.target.value)}>
                                <option name={null}  value={null}>Seleziona cartella...</option>
                                <option name={"tutti"}  value={"tutti"}>tutti</option>
                                    {categorieDocumentiUtenteEsterno.map((el,) => (
                                        <option name={el.nome} value={el}>{el}</option>
                                    ))}
                                </select>                            
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                :
                ""
                }
                {tabSelected == "anagrafica" ?
                    <div className="row row_datapicker">
                        <div className="item_2">
                            <h4>Modifica utente esterno</h4>
                            <div>
                                <div className="w-form">
                                    <form id="nuovo_dipendente" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Nome</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="nome" value={nome} data-name="nome" placeholder="Inserisci nome" id="nome" onChange={e => onChangeSetDatiDipendente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Cognome</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="cognome" value={cognome} data-name="cognome" placeholder="Inserisci cognome" id="cognome" onChange={e => onChangeSetDatiDipendente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Username</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="username" value={username} data-name="username" placeholder="Inserisci username" required="true" id="username" onChange={e => onChangeSetDatiDipendente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Password</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="passCl" value={passCl} data-name="passCl" placeholder="Inserisci password" required="true" id="passCl" onChange={e => onChangeSetDatiDipendente(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    : tabSelected == "documenti" ?
                        <div className="row">
                            <div className="item_2">
                                <h4>Modifica documenti</h4>
                                <div>
                                    <div className="w-form">
                                        <form id="documenti" name="email-form-documenti" data-name="Email Form documenti" method="get" aria-label="Email Form documenti">
                                            <section class="p-section-faq">
                                                <div class="l-inner">
                                                    {documentiUtenteEsterno.map(documento => (
                                                        <>
                                                        {cartellaSelected == 'tutti' || cartellaSelected == documento.cartella ?
                                                        <details class="p-section-faq__item p-faq js-faq-details">
                                                            <summary class="p-faq__question js-faq-question">Documento<span class="p-faq__icon"></span></summary>
                                                            <div class="p-faq__answer js-faq-answer">
                                                                <div class="p-faq__answer-inner">
                                                                    <DocumentoUtenteEsterno
                                                                       id={documento.id}
                                                                       url={documento.url}
                                                                       nome={documento.nome}
                                                                       onChangeUploadFile={onChangeUploadFile}
                                                                       tipologieDocumenti={tipologieDocumenti}
                                                                       onChangeTipologiaDocumento={onChangeTipologiaDocumento}
                                                                       onChangeNomeDocumento={onChangeNomeDocumento}
                                                                       onChangeCartellaDocumento={onChangeCartellaDocumento}
                                                                       eliminaDocumento={eliminaDocumento}
                                                                       categorieDocumentiUtenteEsterno={categorieDocumentiUtenteEsterno}
                                                                       cartella={documento.cartella}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </details>
                                                        :
                                                        ''
                                                        }
                                                        </>
                                                    ))}
                                                </div>
                                            </section>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                }
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            {tabSelected === 'documenti' ?
                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                        <a onClick={(e) => onClickAggiungiRiga(tabSelected)} className="button w-button">Aggiungi riga</a>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenModificaUtenteEsterno(false)} className="button w-button">Chiudi</a>
                            </div>
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={(e) => onSubmitmodificaUtenteEsterno(e)} className="button w-button">Salva</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ModificaUtenteEsterno.protoTypes = {
    getUtenteEsterno: PropTypes.func.isRequired,
    modificaUtenteEsterno: PropTypes.func.isRequired,
    utenteEsterno: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    utenteEsterno: state.utenteEsterno
});

export default connect(mapStateToProps, { getUtenteEsterno, modificaUtenteEsterno })(ModificaUtenteEsterno);